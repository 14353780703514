import { startTransition } from "react";
// Google Analytics is production only
// import ReactGA from "react-ga4";
import { RemixBrowser } from "@remix-run/react";
import { hydrateRoot } from "react-dom/client";
import { Provider } from "react-redux";

import store from "store";

import { reactiveLocalStorage } from "operations/storage";
import { controlCachedImagesClear } from "operations/common";

import { IMAGE_URLS } from "constants/image-urls";

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.register("/sw-cache.js").catch(error => {
    // eslint-disable-next-line no-console
    console.error(error);
  });
  navigator.serviceWorker.ready.then(registration => {
    registration.active.postMessage({
      images: IMAGE_URLS,
      isClear: controlCachedImagesClear(),
    });
  });
}

startTransition(() => {
  reactiveLocalStorage.subscribeWindow();

  hydrateRoot(
    document.getElementById("_remix"),
    <Provider store={store}>
      <RemixBrowser />
    </Provider>,
  );
  // ReactGA.initialize("G-79X2R2HCZS", { gtagOptions: { send_page_view: true } });
});
